import HeaderAppyShop, {getComponentStaticProps, HeaderProps} from '../../components/header/headerAppyShop'
import FooterAppyShop from '../../components/footer/footerAppyShop'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { config } from '../../config-merchant'
import { useTranslation } from 'next-i18next';
import Meta from '../../components/meta/meta';
import { appyShopConfig } from '../../config';
import styles from '../../styles/client-REC.custom.module.scss'
import Carousel from 'react-elastic-carousel';
import { useRef } from 'react';

export default function Home(props: { headerProps: HeaderProps }) {

    const { i18n } = useTranslation('common');
    const carouselRef = useRef(null)
    let resetTimeout;


    return (
        <>
            <Meta
                title={""}
                description={config.shopSlogan[i18n.language]}
                image={config.merchantDomain + config.defaultMetaImage}
                metaRobots={true}
            />
            <HeaderAppyShop activepage={'home'} headerProps={props.headerProps} />

            <main>
                <div className={`${styles['home']} ${styles['custom']}`}>
                    <div className={styles["cover"]}>
                        <Carousel ref={carouselRef} enableAutoPlay={true} isRTL={false} autoPlaySpeed={8000} showArrows={false} onNextEnd={({ index }) => {
                            clearTimeout(resetTimeout)
                            if (index + 1 === 4) {
                                resetTimeout = setTimeout(() => {
                                    if (carouselRef !== null && carouselRef.current !== null ) {
                                        carouselRef.current.goTo(0)
                                    }
                                }, 8000)
                            }
                        }} renderPagination={({ pages, activePage, onClick }) => <div className={styles["slide-bullets"]}>
                            <div onClick={() => onClick("" + pages[0])} className={activePage === 0 ? styles['active-bullet'] : ""}></div>
                            <div onClick={() => onClick("" + pages[1])} className={activePage === 1 ? styles['active-bullet'] : ""}></div>
                            <div onClick={() => onClick("" + pages[2])} className={activePage === 2 ? styles['active-bullet'] : ""}></div>
                            <div onClick={() => onClick("" + pages[3])} className={activePage === 3 ? styles['active-bullet'] : ""}></div>
                        </div>}>
                            {[1, 2, 3, 4].map((item, index) => <img src={"/assets/images/REC/home-cover-" + (index + 1) + ".png"} alt={"home-cover-" + (index + 1)} key={index} />)}
                        </Carousel>

                    </div>


                    <div className={styles["home-text"]}>
                        <h2>Bienvenue chez Rêve et Créations ! </h2>
                        <p>Mon univers de matières naturelles, laine, alpaga, mohair, soie, également des matières végétales, coton, lin, chanvre, modal… autour du tricot et du crochet.</p>
                        <p>Venez découvrir les bienfaits de la « Tricothérapie » et laissez libre cours à votre créativité !</p>
                        <p>Ghislaine</p>
                    </div>

                    <div className={styles["home-imgs"]}>
                        <div><img src="/assets/images/REC/home-img-1.jpg" /></div>
                        <div><img src="/assets/images/REC/home-img-2.jpg" /></div>
                        <div><img src="/assets/images/REC/home-img-3.jpg" /></div>
                    </div>
                </div>
            </main>
            <FooterAppyShop />
        </>

    )
}

export const getStaticProps = async ({ locale }) => {
    if (config.menu.some(item => item.path === 'home') || config.footerLink.some(item => item.url === '/home')) {
        return {
            props: {
                ...await serverSideTranslations(locale, ['common']),
                headerProps: await getComponentStaticProps(locale)
            },
            revalidate: appyShopConfig.revalidateData
        }
    } else {
        return {
            notFound: true
        }
    }
}



